import {send} from 'ajax';
import {createDropIn} from 'bt_drop_in';
import {recalcPromo, clearPromo, applyPromo, togglePromo} from 'promocode';
import {user_has_more_then_one_order, checkout_user_email} from 'run_payment';
import {fb_track_lead} from 'third_party_custom_callbacks';
import {fromDesktop} from 'hit';

// go to second step if from desktop homepage
  if (fromDesktop) {
    window.scrollTo(0,0); 
    replaceDiv('step-0','step-1');
  }

/*setTimeout(function(){console.log('going for it'); document.getElementById('title_header_mobile_line1').classList.add('line1-out');}, 2000);
setTimeout(function(){console.log('going for it'); document.getElementById('step-1').classList.add('become-visible');}, 2000);
setTimeout(function(){console.log('going for it'); document.getElementById('step-1').classList.remove('currently-faded');}, 2000);
setTimeout(function(){console.log('going for it'); document.getElementById('step-1').classList.add('move');}, 2000);*/
var tel1 = document.getElementById('tel_area');
var tel2 = document.getElementById('tel_first');
var tel3 = document.getElementById('tel_second');
var c_name = document.getElementById('child_name');
var email = document.getElementById('ordering_email');
    
var is_signup = window.location.pathname.includes('signup') || document.getElementById('homepage');

const promocode_checkbox = document.getElementById('giftcode');
const promocode_apply = document.getElementById('apply_promo_button');
const promocode_clear = document.getElementById('clear_promo_link');


createDropIn();

if (is_signup) {
  promocode_checkbox.addEventListener('change', (event) => {togglePromo()});
  promocode_apply.addEventListener('click', (event) => {applyPromo(); 
     document.getElementById('title_header_mobile_line5').classList.add('hidden');
     document.getElementById('title_header_mobile_line6').classList.add('promo');
   } );
  promocode_clear.addEventListener('click', (event) => {clearPromo()});
  
  hide_recaptcha() // hide recaptcha if applicable 

  /*var startTimeout = setTimeout(function(){
    document.getElementById('anime_title_header').classList.add('become-hidden'); 
    window.scrollTo(0, 0);
    document.getElementById('step-0').classList.add('become-visible');
    document.getElementById('step-0').classList.remove('currently-faded');
    document.getElementById('step-tree').classList.add('move-first');
  },100); 
  document.getElementById('anime_title_header').addEventListener('click', function() {
    document.getElementById('anime_title_header').classList.add('become-hidden'); 
    window.scrollTo(0, 0);
    document.getElementById('step-0').classList.add('become-visible');
    document.getElementById('step-0').classList.remove('currently-faded');
    document.getElementById('step-tree').classList.add('move-first');
  });
  */ 

  //ab_test_update(); // ab price test turned off
  //get_ab_test_value();  // ab display test turned off
}

if (is_signup && document.getElementById('submit-payment_button')) {
 document.getElementById('submit-payment_button').addEventListener('click', function() {
   window.location.hash ='#step-4';
   document.getElementById('processing').classList.remove('hidden');
 });
}

if (tel1) {
  tel1.addEventListener('input', (event) => { tel1.classList.remove('error'); if (tel1.value.length >2) { tel2.focus();}}  );
  tel2.addEventListener('input', (event) => { tel2.classList.remove('error'); if (tel2.value.length >2) { tel3.focus();}}  );
  tel3.addEventListener('input', (event) => { tel3.classList.remove('error');}  );
}

if (email) {
  email.addEventListener('click', (event) => { email.classList.remove('error');}  );
}

let step0 = document.getElementById('step-0-next');
if (step0) {
  step0.addEventListener('click', (event) => { window.scrollTo(0,0); replaceDiv('step-0','step-1')}  );
}

if(c_name) {
  c_name.addEventListener('blur', (event) => { window.scrollTo(0,0); } );
}
if(tel3) {
  tel3.addEventListener('blur', (event) => { window.scrollTo(0,0); } );
}
if(email) {
  email.addEventListener('blur', (event) => { window.scrollTo(0,0); } );
}

let step1Next = document.getElementById('step-1-next');
if (step1Next) {
  step1Next.addEventListener('click', (event) => { 
     window.scrollTo(0, 0);
     document.getElementById('title_header_mobile_line3').innerHTML = c_name.value.length > 0 ? "Santa can't wait to show " + c_name.value + " the North Pole!" : "That's OK!  Santa can tell stories without using their name!";
     replaceDiv('step-1','step-2');
  }  );
}

let step2Next = document.getElementById('step-2-next');
if (step2Next) {
  step2Next.addEventListener('click', (event) => { 
     if (tel1.value.length < 3 || tel2.value.length < 3 || tel3.value.length < 4) {
       if (tel1.value.length < 3 ) {
         tel1.classList.add('error');
       }
       if (tel2.value.length < 3 ) {
         tel2.classList.add('error');
       }
       if (tel3.value.length < 3 ) {
         tel3.classList.add('error');
       }
     }
     else {
       window.scrollTo(0, 0);
       replaceDiv('step-2','step-3');
     }
  }  );
}

let step3Next = document.getElementById('step-3-next');
if (step3Next) {
  step3Next.addEventListener('click', (event) => { 

     if (email && (!email.value.includes("@") || !email.value.includes('.') || !email.value.length >0)){
       email.classList.add('error');
     }
     else {
       document.getElementById('form_ordering_email').value = email.value;
       send_test_text(email.value, c_name.value, tel1.value, tel2.value, tel3.value);
     }
  }  );
}

let step5Next = document.getElementById('step-5-next');
if (step5Next) {
  step5Next.addEventListener('click', (event) => { 
       update_order();
       window.scrollTo(0, 0);
       document.getElementById('completed-order').innerHTML = (c_name.value || 'Your child') + ' is all set!';
       replaceDiv('step-5','step-6');
  }  );
}

let step6Next = document.getElementById('step-6-next');
if (step6Next) {
  step6Next.addEventListener('click', (event) => { 
    navigate_to_account();
  });
}

async function hide_recaptcha() {
    const useRecaptcha = await send('GET', '/santa/use_recaptcha');
    var use = useRecaptcha.result;
    if (use.toString() == '0') {
      document.getElementById('recaptcha_div').classList.add('hidden');
      document.getElementById('submit-payment_button').classList.remove('hidden');
    }
}

function navigate_to_account() {
    console.log('user_has_more_then_one_order', user_has_more_then_one_order);
    if (user_has_more_then_one_order) {
      document.getElementById('ordering_email').value = checkout_user_email;
      document.getElementById('send_confirm_me_form').submit(); 
    } 
    else {
      Turbolinks.visit('/accounts/menu');
    }
}

async function update_order() {
  try { 
    let data_string = "order_id=" + document.getElementById('order_id').innerHTML + '&gender=' + document.getElementById('gender').value + '&town=' + document.getElementById('town').value + '&age=' + document.getElementById('age').value + '&pet_type=' + document.getElementById('pet_kind').value + '&pet_name=' + document.getElementById('pet_name').value + '&elf=' + document.getElementById('elf').value;
    console.log('data_string', data_string);
    const data = await send("POST", "/api/internal_ad_lead_update", data_string);
    if (data.status == 'success'){
      console.log('update success');
    }
    else {
      console.log('update failure');
    }
  }
  catch (err) {
    console.log('error saving order extra info: ' + err);
  }
}

async function send_test_text(email, c_name, tel1, tel2, tel3) {
      console.log('received ' + email + ' ' + c_name );
       try { 
         let data_string = "email=" + email + '&childs_name=' + c_name  + '&phone_number=1' + tel1 + tel2 + tel3 + '&site=ab_test_anime' + '&sub=' + document.getElementById('sub').innerHTML + '&ab_test_value=' + document.getElementById('ab_test_value').innerHTML;
         console.log('data_string', data_string);
         console.log('sending fb lead');
         fb_track_lead(email, true);
         const data = await send("POST", "/api/internal_ad_lead", data_string);
          if (data.order_id == 0){
            let err_mes = 'Error with Sign Up - Please Try Again';
            Turbolinks.visit('/santa/signup?message=' + err_mes); 
          }
          else {
            document.getElementById('order_id').innerHTML = data.order_id;
          }
          if (data.err_message == 'none'){
            window.scrollTo(0, 0);
            document.getElementById('title_header_mobile_line5').innerHTML = c_name.length > 0 ? "Santa just sent a text to " + c_name + " with a picture from the North Pole!" : "Santa just sent a text with a picture from the North Pole!"; // this is hidden and currently turned off
            replaceDiv('step-3','step-4');
          } 
          else {
            document.getElementById('title_header_mobile_line5').innerHTML = 'Santa had a problem sending the text to the number provided'; 
          }
        } 
        catch (err) {
          console.log('error sending anime text: ' + err);
        }
}

export function replaceDiv(toGo, toShow){
  document.getElementById(toGo).classList.add('become-hidden');
  document.getElementById(toShow).classList.add('become-visible');
  document.getElementById(toShow).classList.remove('currently-faded');
  document.getElementById('step-tree').classList.add('move-' + toShow);
  var div_button = document.getElementById(toGo + '-next');
  if (div_button) {
    div_button.parentNode.removeChild(div_button);
  }
}


/////   upgrade buttons

var package1 = document.getElementById('package-one-button');
var package2 = document.getElementById('package-two-button');
var radioPackage1 = document.getElementById('radio-package-one');
var radioPackage2 = document.getElementById('radio-package-two');

if (package1) { // ok we are on that page
  package1.addEventListener('click', function() {
    package2.classList.remove('selected');
    package1.classList.add('selected');
    radioPackage1.checked = true;
    radioPackage2.checked = false;
  });
  package2.addEventListener('click', function() {
    package1.classList.remove('selected');
    package2.classList.add('selected');
    radioPackage2.checked = true;
    radioPackage1.checked = false;
  });
}

let upgradeStep = document.getElementById('step-addon-next');
if (upgradeStep) {
  upgradeStep.addEventListener('click', (event) => { 
    upgradeOrder();
  });
}

let finalStep = document.getElementById('no-thanks');
if (finalStep) {
  finalStep.addEventListener('click', (event) => { 
    navigate_to_account();
  });
}

async function upgradeOrder() {
  try { 
    document.getElementById('step-addon-next').classList.add('hidden');
    document.getElementById('no-thanks').classList.add('hidden');
    document.getElementById('processing2').classList.remove('hidden');
    let package_name = document.getElementById('radio-package-two').checked ? 'video' : 'family';
    let data_string = "order_id=" + document.getElementById('order_id').innerHTML + '&package=' + package_name;
    console.log('data_string', data_string);
    const data = await send("POST", "/api/internal_package_upgrade", data_string);
    if (data.status == 'success'){
      console.log('update success');
    }
    else {
      console.log('update failure');
    }
    navigate_to_account();
  }
  catch (err) {
    console.log('error saving order extra info: ' + err);
    navigate_to_account();
  }
}


// This is base call to get ab test value of 'a' or 'b'
// Use this for other ab test functions
async function get_ab_test_value() {
  try {
    // A B price testing
    var ab_queryString = window.location.search;
    var ab_urlParams = new URLSearchParams(ab_queryString);
    const ab_test = await send('GET', '/santa/check_ab_testing?' + ab_urlParams);
    var ab_ = ab_test.result;
    document.getElementById('ab_test_value').innerHTML = ab_test.result_string; 
    console.log('ab test value for this guy is ', ab_);

    return ab_;
  }
  catch (err) {
    console.log('error with ab test update: ' + err);
  }
}

async function ab_test_update() {
  try {
    // A B price testing
    var ab_queryString = window.location.search;
    var ab_urlParams = new URLSearchParams(ab_queryString);
    const ab_test = await send('GET', '/santa/check_ab_testing?' + ab_urlParams);
    var ab_ = ab_test.result;
    console.log('ab test value for this guy is ', ab_);
    if (ab_ == 'b') {
      document.getElementById('title_header_mobile_line7').innerHTML = '<b>$19.95</b> for the year including:';
      document.getElementById('fc_p').innerHTML = 19.95;
      document.getElementById('fc').innerHTML = 19.95; 
      document.getElementById('package_cost').innerHTML = 19.95; 
      document.getElementById('ab_test_value').innerHTML = '19_95_no_text'; 
      document.getElementById('c_selected_package').innerHTML = 16;
      document.getElementById('terms').classList.add('ab');
    }
    else {
      document.getElementById('ab_test_value').innerHTML = 'orig_2023_24_95'; 
    }
    // End of A B price testing
  }
  catch (err) {
    console.log('error with ab test update: ' + err);
  }
}
