import {send} from 'ajax';
import {getCookie} from 'browser';

const _el = window.location.origin.includes('textsfromsanta'); 
//const _el = true; // for testing on dev

const run_gtag = true;
//const run_gtag = false;

export async function fb_track_lead(email, quickForm = false){

  console.log('trying fb_track_lead, quickForm:', quickForm);

  let ip_address_value = document.getElementById('ip_address');
  let unique_id = ip_address_value ? Math.floor(Date.now() / 1000) + ip_address_value.innerHTML : Math.floor(Date.now() / 1000);
 
  if ((_el && window.location.pathname === '/santa/text') || (_el && quickForm)) {

    console.log('sending fb_track_lead');
    fbq('track', 'Lead', {}, {eventID: unique_id});
    send("GET", "/santa/third_party_lead?fbp=" + getCookie('_fbp') + '&fbc=' + getCookie('_fbc') + '&website=' + window.location.href + '&unique_id=' + unique_id + '&email='+email + '&ttp=' + getCookie('_ttp'));

    if (window.location.pathname.includes('text_details') || quickForm) {
      console.log('sending fb_track_lead sign up');
      snaptr('track', 'SIGN_UP', {user_email: email});
    }

    let hash = await digestMessage(email); 
    ttq.identify({ "email": email, "external_id": unique_id }); 
    ttq.track('Subscribe', { "contents": [ { "content_name": "lead" } ], "context": {"user": {"ttp": getCookie('_ttp')}} });

    if (run_gtag) {
      dataLayer.push({'event':'lead'});console.log('sending lead for GtagManager'); 
      // add google callback here too
      // console.log('calling gtag conversion event');
      // gtag('event','conversion', {'send_to': 'AW-827102262/PfS5CPHIwP4CELaosooD'});
    }
  }

}

export function send_ga_ab_test(ab_test, page, send_type){
  if (_el) {
    console.log('sending ga ab_test: ' + ab_test);
    ga('send', 'event', ab_test, page, send_type);
  }
}
export function send_ga_ab_test_bought(ab_test){
  if (_el) {
    //ga('send', 'event', ab_test, 'Bought');
  }
}
export function send_ga_ab_test_purchase(ab_test, amount){
  if (_el) {
    //ga('send', 'event', ab_test, 'Purchase', 'Sale', amount);
  }
}

export function send_fb_purchase(s_amount, payment_id, email, phone){
  if (_el) {
    if (parseFloat(s_amount) > 0) {

      let full_amount =  parseFloat(s_amount); 
      let amount =  parseFloat(s_amount) * 0.8;
      //console.log('sending to fb: ' + amount);

      fbq('track', 'Purchase', {value: full_amount , currency: 'USD'}, {eventID: payment_id});
      snaptr('track', 'Purchase', {price: full_amount , currency: 'USD'});

      console.log('phone', phone);
      
      ttq.identify({ "email": email, "phone_number": phone,  "external_id": payment_id }); 
      ttq.track('PlaceAnOrder', { 
         "value": full_amount,
         "currency": "USD",
         "contents": [ { "content_name": "conversion" } ] ,
         "context": {"user": { "ttp": getCookie('_ttp')}}
        });

      if (run_gtag) {
        dataLayer.push({'event':'packagePurchase', 'purchaseAmt': full_amount});console.log('sending purchase for GtagManager'); 
        // add google callback here too
        /* gtag('event', 'conversion', {
          'send_to': 'AW-827102262/b6wHCOzB5_4CELaosooD',
          'value': amount,
          'currency': 'USD',
          'transaction_id': payment_id
        });
        */
      }
    }
  }
}

export async function digestMessage(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest("SHA-256", data);
  return hash;
}
